<template>
<!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
<div id="question" style="width:100vw">
  <div id="af038e73">
    页面设计中～
  </div>
</div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
export default{
  data(){
    return{
      //在此注释下方添加自定义变量
    }
  },
  mounted(){
  },
  computed:{
  },
  watch:{
  },
  methods:{
    //在此注释下方添加自定义函数或事件
  }
}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#af038e73{
  padding-top:409px;
  padding-bottom:615px;
}
#af038e73{
  text-align:center;
  color:#c9c9c9;
  font-size:40px;
  font-family:PingFang SC;
}
/*在此注释下方添加自定义样式*/
</style>
